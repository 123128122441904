window.onload = function () {
	const placeholder = document.getElementById("placeholder");
	const gif = document.getElementById("gif");
	const gifImage = new Image();
	gifImage.src = gif.src;

	gifImage.onload = function () {
		placeholder.src = gif.src;
	};
};
